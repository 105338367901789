body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: linear-gradient(180deg, rgba(3,20,52,1) 0%, rgba(9,31,90,1) 33%, rgba(9,31,90,0.50) 100%);
  height: 100%;
  margin: 0;
  background-repeat: no-repeat;
  background-attachment: fixed;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face {
  font-family: Gilmer;
  src: local("GilmerRegular"), url("../fonts/Gilmer-Regular.otf") format("opentype");
}
@font-face {
  font-family: Gilmer;
  font-weight: bold;
  src: local("GilmerBold"), url("../fonts/Gilmer-Bold.otf") format("opentype");
}
@font-face {
  font-family: Gilmer;
  font-weight: 500;
  src: local("GilmerRegular"), url("../fonts/Gilmer-Medium.otf") format("opentype");
}
@font-face {
  font-family: Gilmer;
  font-weight: 900;
  src: local("GilmerHeavy"), url("../fonts/Gilmer-Heavy.otf") format("opentype");
}


:root{
  --page-background: #f9f9f9;
  --light-background: #FFFFFF;
  --light-text:#FFFFFF;
  --dark-text: #111111;
  --main-blue1: #031434;
  --main-blue2: #20233D;
  --main-blue3: #091F5A;
  --main-blue4: #ADBED8;
  --main-blue5: #7888A5;
  --main-grey1: #B4B4B4;
  --main-grey2: #E9EAED;
  --main-grey3: #626262;
  --main-grey4: #DBDCE0;
  --main-grey5: #F7F8F9;
  --main-grey6: #979797;;
  --shadow-color: #0a164659;
}